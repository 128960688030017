<template>
    <div>
        <PLATFORM :platform="platform" @platform_change="platform_change">
            <template>
                <div class="btns">
                    <el-link type="primary" @click.stop="setGruopName(1)">
                        添加分组</el-link
                    >
                </div>
            </template>
        </PLATFORM>
        <div class="header">
            <div class="search">
                <div class="searh-box">
                    <el-input
                        placeholder="请输入分组名"
                        size="small"
                        v-model="group_where.groupName"
                    >
                    </el-input>
                    <div class="btn">
                        <el-button
                            type="primary"
                            size="small"
                            @click="keySelect"
                            >搜索</el-button
                        >
                    </div>
                </div>
            </div>
            <!-- <div>更新日期：2022-10-10</div> -->
        </div>
        <div class="main">
            <!-- <div class="main-top">
                <div class="main-top-bar">
                    <div class="tab-box">
                        <div class="tab-list" @click="setGruopName(1)">
                            <i class="el-icon-plus"></i>
                        </div>
                        <div
                            class="tab-list"
                            :class="active == 'douyin' ? 'active' : ''"
                            @click="tab_group('douyin')"
                        >
                            抖音
                        </div>
                        <div
                            class="tab-list"
                            :class="active == 'kuaishou' ? 'active' : ''"
                            @click="tab_group('kuaishou')"
                        >
                            快手
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="main-table">
                <el-table
                    size="mini"
                    :data="garupData"
                    style="width: 100%"
                    v-loading="isLoad"
                    element-loading-text="拼命加载中"
                >
                    <el-table-column prop="groupName" label="分组名">
                    </el-table-column>
                    <el-table-column prop="count" label="账号数">
                    </el-table-column>
                    <el-table-column prop="address" label="创建时间">
                        <template slot-scope="scope">
                            <div>
                                {{
                                    moment(scope.row.createTime).format(
                                        "YYYY-MM-DD HH:mm"
                                    )
                                }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button
                                @click="delGruop(scope.row, scope.$index)"
                                type="text"
                                size="small"
                                >删除</el-button
                            >
                            <el-button
                                @click="lookUser(scope.row, scope.$index)"
                                type="text"
                                size="small"
                                >查看</el-button
                            >
                            <el-button
                                type="text"
                                size="small"
                                @click="setGruopName(2, scope.row)"
                                >重命名</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    :small="true"
                    background
                    :page-size="group_where.size"
                    :current-page="group_where.current"
                    @current-change="current_change"
                    layout="prev, pager, next"
                    :total="group_total"
                >
                </el-pagination>
            </div>
        </div>
        <el-dialog :title="alertTitle" :visible.sync="setName" width="500px">
            <div slot="title">
                添加
                <span v-if="platform == 'douyin'">抖音</span>
                <span v-else-if="platform == 'kuaishou'">快手</span>
                <span v-else-if="platform == 'xigua'">西瓜视频</span>
                <span v-else-if="platform == 'xiaohongsu'">小红书</span>
                <span v-else>今日头条</span>
                授权账号分组
            </div>
            <div>
                <!-- <div class="" v-if="setNameType == 2">原分组名：{{  }}</div> -->
                <el-input
                    v-model="sendData.groupName"
                    placeholder="请输入分组名称"
                    maxlength="20"
                    show-word-limit
                ></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="setName = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="编辑分组" :visible.sync="showUser" width="30%">
            <div class="user-table">
                <el-table
                    :data="users"
                    size="mini"
                    style="width: 100%"
                    height="250"
                >
                    <el-table-column prop="userNickname" label="账号">
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button
                                @click="del_user(scope.row, scope.$index)"
                                type="text"
                                size="small"
                                >移除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <div class="user_page">
                    <el-pagination
                        :small="true"
                        :page-size="group_where.size"
                        :current-page="group_where.current"
                        @current-change="user_page_change"
                        layout="total,prev, pager, next"
                        :total="user_count"
                    >
                    </el-pagination>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <!-- <el-button size="small" @click="showUser = false"
                    >取 消</el-button
                > -->
                <el-button size="small" type="primary" @click="showUser = false"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {
    createAutoGruop,
    editAutoGruop,
    getAutoGruopList,
    delAutoGruopList,
    groupUser,
    removeUser,
} from "@/api/auth";

import PLATFORM from "@/components/auth/platform";
import moment from "moment";
export default {
    name: "",
    components: { PLATFORM },
    data() {
        return {
            platform: "douyin",
            showUser: false,
            user_where: {
                id: "",
                current: 1,
                size: 10,
            },
            user_count: 0,
            users: [],
            // 弹框标题
            alertTitle: "",
            // 平台类型'douyin'抖音'kuaishou'快手
            active: "douyin",
            //   分组记录
            garupData: [], //分组记录
            setNameTxt: "", //修改前的名字
            setName: false, //新增分组开关
            isDel: false, // 删除分组开关
            setNameType: "", // 1新增，2修改
            selectGruop: "", //查询分组名
            sendData: {
                //交互数据
                id: "", //分组id
                groupName: "", //分组名
            },
            group_total: 0,
            group_where: {
                // 页码
                groupName: "",
                platformType: "douyin",
                size: 10,
                current: 1,
            },
            isLoad: false,
        };
    },

    mounted() {},
    created() {
        this.getGruop();
    },
    methods: {
        moment,
        platform_change(val) {
            this.platform = val;
            this.group_where.groupName = "";

            this.current_change();
        },
        current_change(val) {
            this.group_where.current = val;
            this.getGruop();
        },
        tab_group(type) {
            this.active = type;
            this.group_where.current = 1;
            this.group_where.platformType = type;
            this.getGruop();
        },
        // 通过分组查询成员
        getUsers() {
            let where = this.user_where;
            groupUser(where).then((res) => {
                // 获取分组
                this.users = res.data.records;
            });
        },
        closeUser() {
            this.users = [];
        },
        handleClose() {},
        handleClick() {},
        lookUser(row, index) {
            this.open_index = index;
            this.user_where.id = row.id;
            this.get_user_list();
        },
        async get_user_list() {
            let where = this.user_where;
            let res = await groupUser(where);
            if (res.code == 200) {
                this.users = res.data.records;
                this.user_count = res.data.total;
                this.showUser = true;
            } else {
                this.$message.error("该分组暂无数据");
            }
        },
        user_page_change(val) {
            console.log(this.user_where);
            let where = this.user_where;
            where.current = val;
            this.get_user_list();
        },
        // 修改或者新增分组弹框
        setGruopName(type, res) {
            this.setNameType = type;
            this.setName = true;
            if (type == 1) {
                this.alertTitle = "添加";
            }
            if (type == 2) {
                this.alertTitle = "修改";
                this.sendData.id = res.id;
                this.setNameTxt = res.groupName;
            }
        },
        // 关键字查询
        keySelect() {
            let select;
            if (this.selectGruop != "") {
                select = {
                    groupName: this.selectGruop,
                    size: 10,
                    current: 1,
                };
            } else {
                select = this.pages;
            }
            this.getGruop(select);
        },
        // 分页查询
        getGruop() {
            this.isLoad = true;
            let where = this.group_where;
            where.platformType = this.platform;

            getAutoGruopList(where).then((res) => {
                if (res.code == 200) {
                    this.garupData = res.data.records;
                    this.group_total = res.data.total;
                } else {
                    this.$message.error(res.msg);
                }

                this.isLoad = false;
            });
        },
        delGruop(res, index) {
            // console.log(index);
            let uid = res.id;
            let groupName = res.groupName;
            this.$confirm(`确认要删除 ${groupName}, 是否继续?`, "删除提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                delAutoGruopList(uid).then((res) => {
                    if (res.code == 200) {
                        this.garupData.splice(index, 1);
                        this.$message({
                            type: "success",
                            message: "删除成功!",
                        });
                    }
                });
            });
        },
        del_user(res, index) {
            console.log(index);
            let data = {
                userGroupList: [],
                groupId: "",
            };
            let userIds = [];
            userIds.push(res.id);
            data.userGroupList = JSON.stringify(userIds);
            data.groupId = this.user_where.id;

            removeUser(data).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    this.users.splice(index, 1);
                    let open_index = this.open_index;
                    this.garupData[open_index].count -= 1;
                    this.$message.success("移除成功");
                }
            });
        },

        async submit() {
            // 分组名提交
            // 提交
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            if (this.setNameType == 2) {
                // 修改
                let _this = this;
                await editAutoGruop(this.sendData).then((res) => {
                    if (res.code == 200) {
                        this.$message.success(
                            `成功将分组 “${_this.setNameTxt}” 改成 “${this.sendData.groupName}”！`
                        );
                        // 修改数据
                        this.getGruop();
                        // 隐藏弹框
                        this.setName = false;
                        // 请求参数清空
                        // this.sendData.groupName = this.sendData.id = "";
                    } else {
                        this.$message.error("修改分组失败");
                    }
                });
            }
            if (this.setNameType == 1) {
                // 新增
                // console.log("新增分组");
                delete this.sendData.id;
                this.sendData.platformType = this.platform;
                await createAutoGruop(this.sendData).then((res) => {
                    if (res.code == 200) {
                        this.$message.success(
                            `成功增加 “${this.sendData.groupName}” 分组！`
                        );
                        this.getGruop();
                        this.setName = false;
                        this.sendData.groupName = "";
                    } else {
                        this.$message.error("增加分组失败");
                    }
                });
            }
            // this.sendData.groupName = this.sendData.id = "";
            loading.close();
        },
    },
};
</script>

<style lang="less" scoped>
.user_page {
    padding: 6px 0;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #f6f9ff;
    padding: 20px 10px;
    .searh-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .input-name {
            flex: 0 0 80px;
        }
    }
}
.tab-box {
    padding: 10px;
    display: flex;
    align-items: center;
    .tab-list {
        color: #777373;
        padding: 10px;
        cursor: pointer;
    }
    .tab-list.active {
        font-weight: bold;
        color: #2f2d2d;
    }
    i {
        background-color: #f3f3f3;
        font-size: 12px;
        padding: 3px;
    }
}
</style>